import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO, Listen } from "components";
import { PreviousEpisode, NextEpisode, PlayEpisode } from "components/episode";
import {
	LatestEpisodesList,
	HostsList,
	ExtendedDescription,
	Gallery,
	RelatedContent,
} from "components/podcast";
import { Banner } from "components/transcript";
import { Section } from "components/layout";
import Image from "gatsby-image";
import Helmet from "react-helmet";
import { getGatsbyImage } from "helpers";
import _ from "lodash";
import BlockContent from "@sanity/block-content-to-react";
import useMenu from "state/useMenu";
/* Transcript Template
============================================================================= */

const TranscriptTemplate = ({ pageContext, location }) => {
	// Config
	const { podcast, episode } = pageContext;
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// Render
	return (
		<>
			<Helmet title={`${podcast[0].name}: ${episode.name} (Transcript)`}>
				<meta name="image" content={podcast[0].image.image.asset.url} />
				<meta
					property="og:title"
					content={`${podcast[0].name}: ${episode.name}`}
				/>
				<meta
					property="og:image"
					content={podcast[0].image.image.asset.url}
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:image"
					content={podcast[0].image.image.asset.url}
				/>
				<meta property="og:url" content={location.href} />
				<meta property="og:description" content={episode.description} />
				<meta property="og:type" content="article" />
			</Helmet>
			<Banner episode={episode} podcast={podcast[0]} />
			<section
				role="region"
				aria-label="Play Episode"
				className={`w-full horizontal-padding relative pt-2`}
			>
				<PlayEpisode
					episode={episode}
					location={location}
					noTranscript
				/>
			</section>
			<Section
				role="region"
				title="Episode Transcript"
				ariaLabel="Episode Transcript"
				className="horizontal-padding padding-top padding-bottom"
			>
				<BlockContent
					blocks={episode._rawTranscript}
					className="block-content prose-lg"
					renderContainerOnSingleChild
				/>
			</Section>
		</>
	);
};

/* Export
============================================================================= */

export default TranscriptTemplate;
